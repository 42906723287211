<template>
  <div style="display: inline-block">
    <!-- 云上传 -->
    <template v-if="whether == 'true'">
      <el-upload class="upload-demo" ref="upload" action="#" :multiple="multiple"  :show-file-list="showFile"
        :on-remove="handleRemove" :http-request="httpRequest" :file-list="fileList">
        <el-button :type="btnType">{{ btnMsg }}<i class="el-icon-upload el-icon--right"></i></el-button>
      </el-upload>
    </template>
    <!-- 本地上传 -->
    <template v-if="whether == 'false'">
      <el-upload class="upload-demo" ref="upload" :action="uploadSrc" :headers="headers" :multiple="multiple" :data="fileData" :show-file-list="showFile"
        :on-remove="handleRemove" :on-progress="handleProgress" :on-change="changeFile" :before-upload="beforeUpload" :before-remove="beforeRemove"
        :file-list="fileList">
        <el-button :type="btnType" size="mini">{{ btnMsg }}<i class="el-icon-upload2 el-icon--right"></i></el-button>
      </el-upload>
    </template>
  </div>
</template>

<script>
// 云上传
import { uploadFile, getExt } from "@/utils/upload";
import md5 from "js-md5";
// 本地
import { getToken } from "@/utils/auth";
const schoolid = sessionStorage.getItem("schoolid") * 1;
export default {
  props: {
    // 展示
    files: {
      type: Array,
      default: () => new Array(),
    },
    // 多选-单选
    multiple: {
      type: Boolean,
      default: true,
    },
    // 是否显示
    showFile: {
      type: Boolean,
      default: true,
    },
    // 循环组件中上传使用
    loops: {
      type: Object,
    },
    // 云文件区分
    cloudType: {
      type: String,
    },
		// 业务模块名称
		module: {
			type: String,
			default: "",
		},
    // 素材包是否解压(0 不解压 1解压)
    is_unzip: {
      type: String | Number,
      default: 0,
    },
    isClear: {
      type: Boolean,
      default: false,
    },
    // 按钮配置
    btnType: {
      type: String,
      default: 'primary',
    },
    btnMsg: {
      type: String,
      default: '点击上传',
    },
    // 问题Id
    qid: {
      type: [Number, String],
      default: -1
    },
    // 答案Id
    aid: {
      type: [Number, String],
      default: -1
    },
  },
  data() {
    return {
      fileList: [],
      uploadSrc: process.env.VUE_APP_BASE_API + "/common/upload-file/upload",
      headers: {
        Authorization: "Bearer " + getToken(),
        fromtype: 2,
        identifier: 2,
        schoolid: schoolid ? schoolid : 1,
				"app-version": "",
				"request-time": "",
				"request-sign": "",
      },
      fileData: {
        is_unzip: this.is_unzip,
				module: this.module,
      },
      whether: "",
      fileArr: [],
      oldFiles: "",
      newFiles: [],
    };
  },
  watch: {
    files: {
      handler(val) {
        if (val && val.length > 0) {
          this.fileList = val;
        } else {
          this.fileList = [];
        }
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
		// 获取 whether，确认是否是云上传
		this.whether = sessionStorage.getItem("whether");
		this.setHeaders(); // 配置上传默认请求头

    // 深拷贝
    this.oldFiles = JSON.stringify(this.files);
    this.newFiles = JSON.parse(this.oldFiles);
  },
  methods: {
		// 配置上传默认请求头
		setHeaders() {
			const date = new Date();
			const c_time = Math.trunc(date.getTime() / 1000);
			const sign_time = c_time.toString();
			const app_version = "v1.0";
			this.headers["app-version"] = app_version;
			this.headers["request-time"] = c_time;
			this.headers["request-sign"] = md5(md5(sign_time) + app_version);
		},
		/**************************** 上传前准备 ****************************/
		// 文件状态改变时(添加文件、上传成功和上传失败)
    changeFile(file, fileList) {
		  this.setHeaders(); // 配置上传默认请求头
      // console.log(this.loops, 'loops')
      if (this.loops) {
        this.loops.show = true;
      }

      if (file.response && file.response.code != 0) {
        this.$message.error(file.response.message);
      }
      if (file.response && file.response.code == 0) {
        if (this.loops) {
          this.loops.show = false;
        }
      }
      // console.log(fileList, 'fileList')
      this.loop(fileList);
    },
    // 循环处理
    loop(fileList) {
      let arr = [];
      fileList.forEach((el) => {
        if (el.uid) {
          const params = {
            url: el.url,
            name: el.name,
            size: el.size,
            ext: el.ext,
          };
          if(el.url){
            arr.push(params);
          }
        }
        if (el.response && el.response.data && el.response.data.length) {
          const params = {
            url: el.response.data[0].path,
            name: el.name,
            size: el.size,
            ext: el.response.data[0].ext,
          };
          arr.push(params);
          if (this.isClear) {
            this.$refs.upload.clearFiles();
          }
          // this.$refs.upload.clearFiles()
        }
      });
      
      let key = { qid: this.qid, aid: this.aid }; // 富文本对应的ID，方便对应回复的问题
      if (this.loops) {
        let params = {
          src: arr,
          loop: this.loops,
        };
        this.$emit("setUrl", params, key);
      } else {
        this.$emit("setUrl", JSON.stringify(arr), key);
      }
    },
		// 上传文件之前，返回false或 Promise且被reject 则 禁止上传
		beforeUpload(file) {
			// console.log(file);
		},
		/**************************** 普通上传：文件走组件自带的上传方法 ****************************/
		// 文件上传中……
		handleProgress(event, file, fileList) {
      // console.log(event, file, fileList);
		},

		/**************************** 云上传：文件走自定义上传方法，上传到云端 ****************************/
		// 云上传方法覆盖
    httpRequest(option) {
      // console.log(option, 'option选中得文件')
      this.fileArr = [];
      uploadFile(option.file, this.afterupload, this.cloudType);
    },

    // 上传的回调函数
    afterupload(file, data) {
      const params = {
        url: data.Key,
        name: file.name,
        size: file.size,
        ext: file.name.substring(file.name.lastIndexOf(".") + 1),
      };
      this.fileArr.push(params);
      //  console.log(this.fileArr, ' this.fileArr')
      // this.newFiles = JSON.parse(this.oldFiles)
      //  console.log(this.newFiles, ' this.newFiles')
      //编辑时上次上传得文件
      const existFiles = this.newFiles.map((val) => {
        return {
          url: val.url,
          name: val.name,
          size: val.size,
          ext: val.ext,
        };
      });
      if (this.loops) {
        const params = {
          src: [...this.fileArr, ...existFiles],
          loop: this.loops,
        };
        this.$emit("setUrl", params);
      } else {
        this.$emit("setUrl", JSON.stringify([...this.fileArr, ...existFiles]));
      }
      // console.log([...this.fileArr, ...existFiles], '88888')
    },
		/**************************** 处理已上传文件 ****************************/
    // 删除图片拦截
    beforeRemove(file, fileList){
      // console.log(file);
    },
		// 删除
    handleRemove(file, fileList) {
      // console.log(file, '删除项')
      if (!this.whether) {
        this.loop(fileList);
      } else {
        // 记录删除的文件
        if (file.id) {
          this.newFiles = this.newFiles.filter((item) => item.id != file.id);
          // console.log(this.newFiles, 'this.newFiles')
        }
        this.cloudLoop(fileList);
      }
    },
    // 云文件处理
    cloudLoop(fileList) {
      // console.log(fileList, '云删除之后')
      let arr = [];
      fileList.forEach((val) => {
        if (val.uid) {
          const params = {
            url: val.url,
            name: val.name,
            size: val.size,
            ext: val.ext,
          };
          arr.push(params);
        } else {
          const params = {
            url:this.cloudType + "/" + md5(val.uid.toString()) + getExt(val.raw.name),
            name: val.raw.name,
            size: val.raw.size,
            ext: val.raw.name.substring(val.raw.name.lastIndexOf(".") + 1),
          };
          arr.push(params);
        }
      });
      if (this.loops) {
        const params = {
          src: arr,
          loop: this.loops,
        };
        this.$emit("setUrl", params);
      } else {
        this.$emit("setUrl", JSON.stringify(arr));
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.upload-demo {
  width: 100%;
}
</style>
